import React from 'react'
import { CircleLoader } from 'react-spinners';

const FullscreenLoadingSpinner = ({ loading }) => {
    return (
        <div className={`min-h-screen flex justify-center items-center ${loading ? "opacity-100" : "hidden"} `}>
                <CircleLoader color={'#000000'} loading={loading} size={100} css=""/>
        </div>
    )
}

export default FullscreenLoadingSpinner
